import { mainAxios } from "@/plugins/axios";
import {ObtenerPost2,Editar } from '@/Generico/Conexion';
import { PagoProcesarRequest, PagoViewModelResponse, PagoViewQuery } from "../Modelos/PagoModel";
export async function ObtenerRegistroPagos(request:PagoViewQuery)
{
    return await ObtenerPost2<PagoViewModelResponse,PagoViewQuery>('pago/obtenerPago',request,mainAxios);
}

export async function RegistrarPago(request:PagoProcesarRequest)
{
    return Editar<PagoProcesarRequest>('pago/registrarpagooperador',request,mainAxios);
}