








































































































import { ProcesoModel,listadoProceso, ServicioModel, listadoServicio, PagooModel, listadoPago } from '@/entidades/Sistema/SitemaModal';
import {PagoViewModelResponse, PagoViewQuery,PagoProcesarRequest} from '@/entidades/AdministracionPago/Modelos/PagoModel';
import { ObtenerRegistroPagos, RegistrarPago} from '@/entidades/AdministracionPago/Proceso/PagoServicio';
import {Vue,Component} from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import moment from 'moment';
import {DescargarPdf} from '@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta';
import { secondAxios } from "@/plugins/axios";
@Component({
    name : 'Pago',
    components :{
       'tabla' :() => import('@/components/General/TablaGeneralFinal.vue')

    }
})
export default class Pago extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    listadoPagos:Array<PagoViewModelResponse> =[];
    procesos:Array<ProcesoModel> = [];
    servicios:Array<ServicioModel> = [];
    pagos:Array<PagooModel> = [];
    procesoId : number = 0;
    servicioId: number = 0;
    pagoId:number =0;
    dialogPago:boolean=false;
    desde:string ="";
    menu:boolean =false;
    item:PagoViewModelResponse|undefined = new PagoViewModelResponse();
    //#region Campos Tabla
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Fecha",sortable:true,value:'fechaRegistro'},
            {text:"Proceso",sortable:false,value:'proceso'},
            {text:"# Parte",sortable:true,value:'idServicio'},
            {text:"Contratista",sortable:true,value:'contratistaName'},
            {text:"Servicio",sortable:true,value:'servicio'},
            {text:"Aprobador",sortable:false,value:'fullName'},
            {text:"WorkFlow",sortable:false,value:'codigoWorkFlow'},
            {text:"Vendor",sortable:false,value:'vendorSap'},
            {text:"Estado",sortable:false,value:'estadoProceso'},
            {text:"Action",sortable:false,value:'actions'},

    ];
    //#endregion
    //#region ObtenerServicio
    obtenerServicio()
    {
      this.servicios = listadoServicio().filter(t=>t.procesoId == this.procesoId);
    }

    modalDialog(item:PagoViewModelResponse)
    {
        this.dialogPago = true;
        this.item = item;
    }
    closeDesde(fecha:string)
     {
        this.desde = fecha;
        this.menu = false;


     }
    //#endregion

    //#region ConexionApis
    async obtenerPagos()
    {
        var descripcionServicio = this.servicios.find(t=>t.servicioId == this.servicioId)?.descripcion;
        var descripcionProceso = this.procesos.find(t=>t.procesoId == this.procesoId)?.descripcion;
        var requestPago = new PagoViewQuery(descripcionProceso == undefined ? "" : descripcionProceso,descripcionServicio == undefined ? "" : descripcionServicio,this.pagoId);
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo informacion"));
            var rsp = await ObtenerRegistroPagos(requestPago);
            if(rsp.isSuccess)
            {
                console.log(rsp.isResult);
                this.listadoPagos = rsp.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
                this.listadoPagos = [];
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",2000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }

    }
    async registrarPago()
    {
        try
        {
            this.changeLoading(new Loading(true,"Procesando pago..."));
            if(this.item?.codigoWorkFlow  == "")
            {
                this.changeAlerta(new Alerta("Ingrese un codigo valido",true,"danger",3000));
                return;
            }
            if(this.item != undefined)
            {
                let request = new PagoProcesarRequest(this.item?.id,this.usuarioId,new Date(this.desde),this.item?.codigoWorkFlow);
                var response = await RegistrarPago(request);
                if(response.isSuccess)
                {
                    this.changeAlerta(new Alerta(response.isMessage,true,"primary",3000));
                    this.obtenerPagos();
                    this.dialogPago = false;
                }
                else
                {
                    this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
                }
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }


    }

    async descargarPdf(item:PagoViewModelResponse)
    {
       this.changeLoading(new Loading(true,"Descargando pdf"));
      if(item.proceso === "FORESTAL")
      {
        try
        {
          var url = `parte/generarpdf/${item.idServicio}`
           let {data} = await secondAxios({
            method : 'GET',
            url : url,
                responseType : 'blob'
         });
         console.log(data);



          let blob = new Blob([data], {type: 'application/pdf'})
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        }
        catch(error)
        {
          this.changeAlerta(new Alerta("No se encontro el parte seleccionado",true,"danger",3000))
        }
        finally
        {
          this.changeLoading(new Loading(false,""));
        }

      }

    }
    //#endregion
    mounted() {
      this.desde = moment(new Date()).format("YYYY-MM-DD");
      this.procesos = listadoProceso();
      this.pagos = listadoPago();
    }
}
