export class PagoViewModelResponse 
{
    id: number=0;
    proceso: string="";
    servicio: string="";
    constratistaId: number=0;
    contratistaName: string="";
    placa: string="";
    valor: number=0;
    idServicio: number=0;
    usuarioId: string="";
    fechaPago: string="";
    fullName: string="";
    emailNotificacion: string="";
    codigoWorkFlow: string="";
    estadoProceso: string="";
    estado:number =0;
    vendorSap: string="";
    fechaRegistro:string="";
}

export class PagoViewQuery 
{
    proceso:string="";
    servicio: string="";
    estado: number=0;
    constructor(_proceso:string,_servicio:string,_estado:number)
    {
        this.proceso = _proceso;
        this.servicio = _servicio;
        this.estado = _estado;
    }
}

export class PagoProcesarRequest {
    id: number;
    usuarioId: string;
    fechaPago: Date;
    codigoWorkFlow: string;

    constructor(_id:number,_usuarioId:string,_fechaPago:Date,_codigoFlow:string)
    {
        this.id = _id;
        this.usuarioId = _usuarioId;
        this.fechaPago=_fechaPago;
        this.codigoWorkFlow = _codigoFlow;
    }
}